import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <div className="c12 title">
        <h1 className="content-web emoji">👋</h1>
        <div>
            <h1 className="content-mobile">👋</h1>
            <div className="hero">
            <h1>Hi, I'm Eden.</h1>
            <h3 className="medium">curious thinker | challenge seeker | self starter</h3>
            </div>
        </div>
    </div>
    <div className="sticky-tab">Coded by me 🤓</div>
    <div className="content">
      <Link to="/portfolio/kfitzah">
        <div className="hvr-float exp exp-kfitzah">
            <div className="exp-content">
              <h2 className="portfolio-item">Kfitzah</h2>
              <h5 className="medium">Teaching coding to African refugees</h5>
            </div>
        </div>
      </Link>

      <Link to="/portfolio/hackathon">
          <div className="hvr-float exp exp-hackathon">
            <div className="exp-content">
              <h2 className="white portfolio-item">Hack for Olim Hackathon</h2>
              <h5 className="medium white">Merging code and communities</h5>
            </div>
          </div>
      </Link>

      <Link to="/portfolio/what-the-tech">
        <div className="hvr-float exp exp-tech">
          <div className="exp-content">
              <h2 className="white portfolio-item">What the Tech</h2>
              <h5 className="medium white">Breaking down barriers to tech</h5>
          </div>
        </div>
      </Link>

      <Link to="/portfolio/design-thinking">
        <div className="hvr-float exp exp-design">
            <div className="exp-content">
                <h2 className="white portfolio-item">Design Workshops & Consulting</h2>
                <h5 className="medium white">Braining up a storm</h5>
            </div>
        </div>
      </Link>

      <Link to="/portfolio/art">
        <div className="hvr-float exp exp-art">
            <div className="exp-content">
              <h2 className="portfolio-item">Art</h2>
              <h5 className="medium">Bursts of creative energy</h5>
            </div>
        </div>
      </Link>

      <Link to="/portfolio/lemonade">
        <div className="hvr-float exp exp-lemonade">
            <div className="exp-content">
              <h2 className="white portfolio-item">Lemonade</h2>
              <h5 className="medium white">Forget everything you know about insurance</h5>
            </div>
        </div>
      </Link>
    </div>
  </Layout>
)

export default IndexPage
